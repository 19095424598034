(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/selectors/assets/javascripts/games.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/selectors/assets/javascripts/games.js');

'use strict';

const {
  gamesConstants
} = svs.components.marketplaceData;
const {
  isAuthorizedForContainer
} = svs.components.marketplaceRetailAdmin.helpers;
const {
  sortByGameColumn
} = svs.components.marketplaceRetailAdminData.selectors.helpers;

const selectActiveGamesForTemplate = (state, _ref) => {
  let {
    gameTemplateId,
    groupId
  } = _ref;
  const statuses = [gamesConstants.GAME_STATUS_OPEN, gamesConstants.GAME_STATUS_SUBMITTABLE];
  return Object.keys(state.Games).length > 0 && state.Games[groupId] ? state.Games[groupId].data.filter(game => game.templateId === gameTemplateId && statuses.indexOf(game.status) > -1) : [];
};

const selectGamesByStatus = (_ref2, _ref3) => {
  let {
    Games
  } = _ref2;
  let {
    groupId,
    status
  } = _ref3;
  return Games ? ((Games[groupId] || {}).data || []).filter(game => {
    if (!status) {
      return true;
    }
    return status.indexOf(game.status) !== -1;
  }) : [];
};

const selectGames = (state, _ref4) => {
  let {
    groupId
  } = _ref4;
  return state.Games[groupId] || {};
};

const selectSortedGames = (state, _ref5) => {
  let {
    groupId
  } = _ref5;
  const games = selectGames(state, {
    groupId
  }) || [];
  const sortedGames = sortByGameColumn({
    orderBy: 'buyinCloseTime',
    order: 'asc'
  }, {
    games
  });
  return sortedGames;
};

const selectGamesByProduct = (state, _ref6) => {
  let {
    games,
    product
  } = _ref6;
  if (!product) {
    return games;
  }
  if (product === 'topptipset') {
    return games.filter(game => game.productId === svs.utils.products.productIds.T8STRYK || game.productId === svs.utils.products.productIds.T8EXTRA || game.productId === svs.utils.products.productIds.T8);
  }
  return games.filter(game => game.productId === svs.utils.products.resolveProductId(product));
};

const selectSortedGamesByProductStatus = (state, _ref7) => {
  let {
    groupId,
    product,
    status,
    sort
  } = _ref7;
  if (!groupId) {
    return [];
  }
  const gamesByStatus = selectGamesByStatus(state, {
    groupId,
    status
  }) || [];
  if (!product) {
    const sortedGames = sortByGameColumn(sort, {
      games: gamesByStatus
    });
    return sortedGames;
  }
  const gamesByProduct = selectGamesByProduct(state, {
    games: gamesByStatus,
    product
  });
  const sortedGames = sortByGameColumn(sort, {
    games: gamesByProduct
  });
  return sortedGames;
};

const selectGamesHistory = (state, _ref8) => {
  let {
    groupId
  } = _ref8;
  return (state.GamesHistory || {})[groupId] || {};
};

const selectIsPermissibleToContainer = (state, _ref9) => {
  let {
    activatedDrawId,
    groupId
  } = _ref9;
  const game = selectGames(state, {
    groupId
  }).data.find(game => game.id === activatedDrawId);
  return isAuthorizedForContainer({
    containerOwnerId: game.editorId,
    editorId: state.EditorSession.editorId,
    editorRole: state.EditorSession.role
  });
};
setGlobal('svs.components.marketplaceRetailAdminData.selectors.games', {
  selectActiveGamesForTemplate,
  selectGames,
  selectGamesHistory,
  selectGamesByStatus,
  selectGamesByProduct,
  selectIsPermissibleToContainer,
  selectSortedGames,
  selectSortedGamesByProductStatus
});

 })(window);