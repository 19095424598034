(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/selectors/assets/javascripts/helpers/sort-games.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/selectors/assets/javascripts/helpers/sort-games.js');
"use strict";


const sortByGameColumn = (_ref, state) => {
  let {
    orderBy: column,
    order
  } = _ref;
  if (!state || !state.games) {
    return [];
  }
  const orderAsc = order === 'asc';
  const sort = (a, b) => {
    if (typeof a === 'string' && typeof b === 'string') {
      return a.toLowerCase().localeCompare(b.toLowerCase()) * (orderAsc ? 1 : -1);
    }
    if (a > b) {
      return orderAsc ? 1 : -1;
    }
    if (a < b) {
      return orderAsc ? -1 : 1;
    }
    return 0;
  };
  const defaultSort = (a, b) => sort(a.value[column], b.value[column]);
  const dateSort = (a, b) => sort(new Date(a.value[column]), new Date(b.value[column]));
  const fractionSort = (a, b) => sort(a.value[column], b.value[column]);
  const sortColumn = sortFunc => {
    const games = state.games || [];
    const mapped = games.map((el, i) => ({
      index: i,
      value: el
    }));

    mapped.sort(sortFunc);
    return mapped.map(el => games[el.index]);
  };

  switch (column) {
    case 'drawCloseTime':
      return sortColumn(dateSort);
    case 'soldShares':
      return sortColumn(fractionSort);
    case 'buyinCloseTime':
      return sortColumn(dateSort);
    default:
      return sortColumn(defaultSort);
  }
};
setGlobal('svs.components.marketplaceRetailAdminData.selectors.helpers.sortByGameColumn', sortByGameColumn);

 })(window);