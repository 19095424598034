(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/selectors/assets/javascripts/is-loading-user.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/selectors/assets/javascripts/is-loading-user.js');

'use strict';

function isLoadingUser(state, userId) {
  const loadingUser = state.LoadingUsers[userId];
  if (!loadingUser) {
    return false;
  }
  return loadingUser.loading;
}
setGlobal('svs.components.marketplaceRetailAdminData.selectors.isLoadingUser', isLoadingUser);

 })(window);