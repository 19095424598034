(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/selectors/assets/javascripts/user-selectors.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/selectors/assets/javascripts/user-selectors.js');

'use strict';

const hasIncompleteUser = state => {
  if ((state.Users.users || []).length > 0) {
    return (state.Users.users || []).find(_ref => {
      let {
        isIncompleteUser
      } = _ref;
      return isIncompleteUser;
    });
  }
  return undefined;
};

const selectUsers = state => state.Users.users || [];

const selectUser = (state, userId) => {
  const users = selectUsers(state);
  if ((users || []).length > 0) {
    return (users || []).filter(_ref2 => {
      let {
        userId: id
      } = _ref2;
      return id === userId;
    }).pop();
  }
  return undefined;
};
const selectIsLoading = state => state.Users.loading;
setGlobal('svs.components.marketplaceRetailAdminData.selectors.userSelectors', {
  hasIncompleteUser,
  selectUsers,
  selectUser,
  selectIsLoading
});

 })(window);