(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/selectors/assets/javascripts/get-user-by-id.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/selectors/assets/javascripts/get-user-by-id.js');

'use strict';

function getUserById(state, findUserId) {
  if ((state.Users.users || []).length > 0) {
    return (state.Users.users || []).filter(_ref => {
      let {
        userId
      } = _ref;
      return userId === findUserId;
    }).pop();
  }
  return undefined;
}
setGlobal('svs.components.marketplaceRetailAdminData.selectors.getUserById', getUserById);

 })(window);