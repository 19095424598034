(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/selectors/assets/javascripts/create-game.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/selectors/assets/javascripts/create-game.js');
'use strict';

const {
  draws: drawsSelectors
} = svs.components.marketplaceData.selectors;
const {
  selectors
} = svs.components.marketplaceRetailAdminData;
const getGame = state => state.CreateGame;
const getDrawEvents = (state, _ref) => {
  let {
    groupId
  } = _ref;
  const {
    drawNumber,
    templateId
  } = getGame(state);
  const {
    productName
  } = selectors.gameTemplates.getGameTemplate(state, groupId, templateId) || {};
  return productName ? drawsSelectors.getDrawEvents(state, {
    drawNumber,
    productName
  }) : undefined;
};
const getDrawEventsByDrawNumber = (state, _ref2) => {
  let {
    drawNumber,
    productName
  } = _ref2;
  return productName ? drawsSelectors.getDrawEvents(state, {
    drawNumber,
    productName
  }) : undefined;
};
const getSelectedOutcomes = (state, _ref3) => {
  let {
    groupId,
    productName,
    activatedDrawId
  } = _ref3;
  const {
    drawNumber
  } = getGame(state);
  const rowId = activatedDrawId ? activatedDrawId : "".concat(groupId, "_").concat(productName, "_").concat(drawNumber);
  return (state.CreateBetProposal[rowId] || {}).data;
};
setGlobal('svs.components.marketplaceRetailAdminData.selectors.createGame', {
  getDrawEvents,
  getGame,
  getSelectedOutcomes,
  getDrawEventsByDrawNumber
});

 })(window);